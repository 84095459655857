import { ActionIcon, Drawer } from "@mantine/core";

import { useGetDeviceType } from "_common/hooks/global";
import { PrimaryButton }    from "_components/atoms/button";
import Icon                 from "_components/atoms/icon";
import Modal                from "_components/atoms/modal";
import Typography           from "_components/atoms/typography";
import {
	useGetAdBlockerDetected,
	useSetAdBlockerDetected
} from "_jotai/rooterPro";

import useStyles from "./styles";

function AdBlockerPopup () {
	const { classes }          = useStyles ();
	const adBlockerDetected    = useGetAdBlockerDetected ();
	const setAdBlockerDetected = useSetAdBlockerDetected ();
	const deviceType           = useGetDeviceType ();
	const isMobile             = deviceType === "mobile";

	function handleClose () {
		setAdBlockerDetected ( false );
	}

	if ( isMobile ) {
		return (
			<Drawer
				classNames      = { { content: classes.drawerContent } }
				onClose         = { handleClose }
				opened          = { adBlockerDetected }
				position        = { "bottom" }
				withCloseButton = { false }
			>
				<Inner handleClose = { handleClose } />
			</Drawer>
		);
	}

	return (
		<Modal
			centered
			classNames      = { { content: classes.modalContent } }
			onClose         = { handleClose }
			opened          = { adBlockerDetected }
			withCloseButton = { false }
		>
			<Inner handleClose = { handleClose } />
		</Modal>
	);
}

function Inner ( { handleClose } ) {
	const { classes } = useStyles ();

	return (
		<>
			<ActionIcon className = { classes.closeButton }
				onClick              = { handleClose }
			>
				<Icon name = { "cross" }
					size      = { 36 }
				/>
			</ActionIcon>

			<Icon name       = { "errorAlert" }
				sameInBothTheme = { true }
				size            = { 56 }
			/>

			<Typography component = { "p" }
				title                = { "DNS/AD Blocker Detected" }
				weight               = { "500" }
			/>

			<Typography color = { "secondary" }
				component        = { "p" }
				size             = { "sm" }
				title            = { "Disable your DNS/AD blocker or try a different browser" }
			/>

			<PrimaryButton fullWidth = { true }
				onClick                 = { handleClose }
				title                   = "Okay"
			/>
		</>
	);
}
export default AdBlockerPopup;
